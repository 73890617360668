import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'
import axios from 'axios'

export default function Our_Panels() {



    const D5ART_BASE_URL = "https://d5art.com"
    const D5ART_SERVER_URL = "https://edu.d5art.com"
    const WYSE_SERVER_URL = "https://wyse.health"
    

    const packagesData = [
        {
            id: 1,
            title: "Corporate Health Package",
            price: "33,000",
            description: "Tailored Wellness for Your Entire Workplace Productivity.",
            features: [
                "Painless swab sample collection.",
                "Comprehensive health checks for employees.",
                "Early detection of health issues to prevent absenteeism."
            ]
        },
        {
            id: 2,
            title: "Corporate Health Package",
            price: "95,500",
            description: "Maximize Your Life’s Potential",
            features: [
                "Painless swab sample collection.",
                "Assess metabolic health and prevent diseases.",
                "Improve quality of life."
            ]
        },
        {
            id: 3,
            title: "Full Metabolic Pathways",
            price: "70,500",
            description: "Unlock the Secrets to Optimal Health",
            features: [
                "Painless swab sample collection.",
                "Comprehensive metabolic overview.",
                "Prevent chronic diseases."
            ]
        }
    ];
    const [mainPackage, setMainPackage] = useState(packagesData[0]);
    const [sidePackages, setSidePackages] = useState(packagesData.slice(1));
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [count, setCount] = useState(1);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        countryCode: "+91",
        phoneNumber: "",
        houseNumber: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        landmark: "",
        selectedPackage: null, 
      });


      useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const status = params.get("status");
        if (status === "success") {
         toast.success("Payment Successful! 🎉");
        } else if (status === "failed") {
          toast.error("Payment Failed. Please try again.");
        }
      }, []);
    
    const validateFormData = (data) => {
        const requiredFields = [
            "firstName", "lastName", "email", "countryCode", "phoneNumber",
            "houseNumber", "address", "city", "state", "postalCode", "landmark"
        ];
    
        for (const field of requiredFields) {
            if (!data[field] || data[field].trim() === "") {
                toast.error(`⚠️ ${field.replace(/([A-Z])/g, ' $1')} is required.`);
                return false;
            }
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(data.email)) {
            toast.error("⚠️ Invalid email address.");
            return false;
        }
    
        if (!/^\d+$/.test(data.phoneNumber)) {
            toast.error("⚠️ Phone number must be numeric.");
            return false;
        }
    
        return true;
    };


    const handlePayment = async () => {
        try {
            if (!validateFormData(formData)) return;

            toast.loading("Creating Order...");
    
            console.log("🔄 Sending Order Request to:", `${D5ART_SERVER_URL}/api/create-order`);
    
            const { data } = await axios.post(`${D5ART_SERVER_URL}/api/create-order`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });
    
            console.log("✅ Order Response:", data);
    
            toast.dismiss(); 
    
            if (!data.success) {
                toast.error("Failed to create order. Try again.");
                return;
            }
    
            console.log("🔄 Sending Order to WYSE:", `${WYSE_SERVER_URL}/api/place-order`);
    
            await axios.post(`${WYSE_SERVER_URL}/api/place-order`, {
                ...formData,
                selectedPackage: mainPackage,
                order_id: data.orderId,
            });
    
            console.log("✅ Order placed successfully, redirecting...");
            
            window.location.href = `${D5ART_BASE_URL}/wyse?order_id=${data.orderId}`;
    
        } catch (error) {
            console.error("❌ Payment Error:", error);
            toast.dismiss();
            toast.error("Failed to process payment. Try again.");
        }
    };
    
      


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
    
    const handlePackageClick = (pkg) => {
        setSidePackages([mainPackage, ...sidePackages.filter((p) => p.id !== pkg.id)]);
        setMainPackage(pkg);
    };

    const openPopup = (pkg) => {
        setSelectedPackage(pkg);
        setIsOpen(true);
        setFormData((prev) => ({
            ...prev,
            selectedPackage: {
              id: pkg.id,
              title: pkg.title,
              price: pkg.price,
            },
          }));
    };

    return (
        <div className=''>
            <Header />

            <div className='Section-1 container'>
                <div className='flex flex-col lg:flex-row gap-10 items-center'>
                    <div className='w-[100%] lg:w-[50%]'>    
                    <video src='/assets/video/WYSE loop video.mp4' className='bg-gradient-to-b from-[#D3F8C2] to-[#D8D8D8] p-3 rounded-[35px] shadow-md fadeInUp' autoPlay muted loop playsInline controls={false} ></video>
                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <h1 className='font-bold text-lg '>DNA Testing at Home – <span className='text-[#8ABE71] font-bold text-lg'>Simple, Fast, and Accurate</span></h1>
                        <h1 className='font-bold text-2xl md:text-4xl 2xl:text-5xl '>Get Your DNA Test Delivered to Your Doorstep! </h1>
                        <p className='leading-8 mt-3 fadeInUp'>Experience the convenience and accuracy of Wyse Health DNA testing at home. Receive a simple, painless sample collection kit, complete the test at home, and get fast, accurate results without stepping into a clinic. Whether you're focused on Sports Performance, Longevity, or Whole Body Metabolic Health, Wyse Health brings the lab to your doorstep.</p>
                    </div>
                </div>
            </div>
            <div className='Section-2  overflow-hidden bg-[#F4F4F4] '>
                <div className='container'>
                    <h1
                        className="wow black text-center visible-slowly-right text-2xl md:text-4xl 2xl:text-5xl font-bold" style={{ zIndex: 1 }}
                        data-wow-delay=".3s"
                    >
                        Health Package
                        <span className="position-relative z-1 mb-40 text-2xl md:text-4xl 2xl:text-5xl ml-2">
                            Categories
                            <img
                                src="assets/img/element/title-badge1.png"
                                alt="img"
                                className="title-badge1 d-md-block d-none w-100 translate-y-2"
                            />
                        </span>
                    </h1>
                    <div className='grid gap-3 md:grid-cols-2 lg:grid-cols-3 mt-10'>
                        <div className='flex fadeInUp items-center gap-3 bg-white border rounded-2xl p-4  duration-300 hover:shadow-md hover:-translate-y-3' data-wow-delay=".3s">
                            <div className='w-[60%]'>
                                <h1 className='font-bold text-lg'>Corporate Health Package</h1>
                                <p className='text-sm mt-3'>Tailored Wellness for Your Entire Workplace Productivity.</p>
                            </div>
                            <div className='w-[40%]'>
                                <img className='mx-auto' src='/assets/img/element/ourpanel1.svg'></img>
                            </div>
                        </div>
                        <div className='flex fadeInUp items-center gap-3 bg-white border rounded-2xl p-4  duration-300 hover:shadow-md hover:-translate-y-3' data-wow-delay=".5s">
                            <div className='w-[60%]'>
                                <h1 className='font-bold text-lg'>Longevity Health Package</h1>
                                <p className='text-sm mt-3'>Maximize Your Life’s Potential</p>
                            </div>
                            <div className='w-[40%]'>
                                <img className='mx-auto' src='/assets/img/element/ourpanel2.svg'></img>
                            </div>
                        </div>
                        <div className='flex fadeInUp items-center gap-3 bg-white border rounded-2xl p-4  duration-300 hover:shadow-md hover:-translate-y-3' data-wow-delay=".7s">
                            <div className='w-[60%]'>
                                <h1 className='font-bold text-lg'>Full Metabolic Pathways</h1>
                                <p className='text-sm mt-3'>Unlock the Secrets to Optimal Health</p>
                            </div>
                            <div className='w-[40%]'>
                                <img className='mx-auto' src='https://wyseglobal.s3.ap-south-1.amazonaws.com/assets/img/element/ourpanel3.svg'></img>
                            </div>
                        </div>
                    </div>
                </div>
            



                <div className='p-[1.5rem] md:p-[4.5rem] pt-0'>
                    <div className='bg-[#EDEDED] px-2 md:px-5 lg:px-10 py-14 rounded-2xl relative overflow-hidden'>
                        <h1 className='font-bold text-2xl text-center'>Our Panels</h1>
                        <div className='mt-7'>
                            <div className='flex flex-col lg:flex-row lg:w-[85%] 2xl:w-[80%] mx-auto gap-3 lg:gap-4 relative' style={{ zIndex: 1 }}>
                                <div className='w-[100%] lg:w-[60%]'>
                                    <div className='corporate_box lg:h-full bg-white rounded-2xl space-y-3 duration-500 shadow-md hover:shadow-xl border hover:-translate-y-3' onClick={() => openPopup(mainPackage)}>
                                    <h1 className='font-Outfit text-2xl font-semibold'>{mainPackage.title}</h1>
                                <h2 className='font-Outfit text-[#8ABE71] font-bold text-2xl lg:text-4xl 2xl:text-5xl'>{mainPackage.price}INR</h2>
                                <p className='text-black font-Outfit font-medium'>{mainPackage.description}</p>
                                        <ul className='font-Outfit space-y-2'>
                                        {mainPackage.features.map((feature, index) => (
                                        <li key={index} className='flex items-center gap-1'>
                                            <img src='/assets/img/icon/tic.svg' alt="tick icon" /> {feature}
                                        </li>
                                    ))}
                                        </ul>
                                        <div className='flex justify-center'>
                                            <a
                                                className="mt-4 w-[75%] common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100"
                                            >   <FontAwesomeIcon icon={faCartShopping} />
                                                Buy Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[100%] lg:w-[40%] gap-3 flex flex-col md:flex-row lg:flex-col'>
                                    

                                {sidePackages.map((pkg) => (
                                    <div
                                        key={pkg.id}
                                        className="w-[100%] md:w-[50%] lg:w-[100%] bg-white rounded-2xl p-3 space-y-1 duration-500 shadow-md hover:shadow-xl border hover:-translate-y-3"
                                        onClick={() => handlePackageClick(pkg)}
                                    >
                                        <h1 className="font-Outfit text-lg font-semibold">{pkg.title}</h1>
                                        <h2 className="font-Outfit text-[#8ABE71] font-bold text-xl md:text-2xl 2xl:text-4xl">
                                        {pkg.price}INR
                                        </h2>
                                        <p className="text-black font-Outfit font-medium">{pkg.description}</p>
                                        {pkg.features && pkg.features.length > 0 && (
                                        <ul className="font-Outfit space-y-2">
                                            {pkg.features.map((feature, index) => (
                                            <li key={index} className="flex items-center gap-1">
                                                <img src="/assets/img/icon/tic.svg" alt="tick icon" /> {feature}
                                            </li>
                                            ))}
                                        </ul>
                                        )}
                                    </div>
                                    ))}



                                   
                                   
                                </div>
                            </div>
                            <img src='/assets/img/element/DNApanel.png' className='absolute z-0 w-[50%] 2xl:w-auto top-0 lg:top-[50%] lg:-translate-y-[50%] left-[68%] 2xl:left-[65%]'></img>
                        </div>
                    </div>
                </div> 




             
              
                
            </div>
            {isOpen && selectedPackage && (
                <div className="fixed inset-0 flex items-center h-full justify-center bg-black bg-opacity-50 px-2 py-5 overflow-auto " style={{ zIndex: 101 }}>
                    <div className="bg-white p-2 lg:p-6 w-[90%] lg:w-[70%] rounded-xl shadow-lg gap-5 flex flex-col lg:flex-row justify-center absolute top-7">
                        <div className='lg:w-[50%] lg:border-r-2 px-2'>
                            <div>
                                <h1 className='text-[#585858] text-xl font-bold'>Order Summary</h1>
                            </div>

                            <div className='flex  gap-2 mt-2'>
                                <div>
                                    <img src='/assets/img/icon/Popup.svg'></img>
                                </div>
                                <div className=' gap-3 justify-between'>
                                    <div>
                                        <p className='text-black text-xl'>{selectedPackage.title}</p>
                                        {/* <p className='text-black flex items-center gap-1'><i class="fa-regular fa-square-plus cursor-pointer" onClick={() => setCount((prev) => prev + 1)}></i>&nbsp;{count}&nbsp;<i class="fa-regular fa-square-minus cursor-pointer" onClick={() => setCount((prev) => Math.max(1, prev - 1))}></i></p> */}
                                    </div>
                                    <div>
                                        <p className='text-[#083B6B] text-xl'>{selectedPackage.price} INR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-[50%] px-2'>
                            <div>
                                <h1 className='text-[#585858] text-xl font-bold'>Payment</h1>
                            </div>
                            <div className='mt-2'>
                                <h3 className='text-[#585858]'>Contact Details</h3>
                            </div>
                            <div className='flex justify-center gap-3 mt-3'>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" 
                                    type="text" name='firstName'
                                        placeholder="Your Name"
                                        value={formData.firstName}
                                        onChange={handleChange} />
                                </div>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2"
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                     />
                                </div>
                            </div>
                            <div className='mt-3'>
                                <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2"
                                 type="text" name="email"
                                 value={formData.email}
                                 onChange={handleChange}
                                 placeholder="Email"
                                      />

                            </div>
                            <div className='mt-3 flex gap-2'>
                                <input className="w-[15%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="number"
                                 name="countryCode"
                                 value={formData.countryCode}
                                 onChange={handleChange}
                                  placeholder="+91" 
                                />
                                <input className="w-[85%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="number" 
                                  name="phoneNumber"
                                  value={formData.phoneNumber}
                                  onChange={handleChange}
                                  placeholder="Phone Number"
                                   />

                            </div>

                            <div>
                                <h1 className='text-[#585858] '>Shipping Details</h1>
                            </div>

                            <div className='mt-'>
                                <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="text"
                                 name="houseNumber"
                                 value={formData.houseNumber}
                                 onChange={handleChange}
                                 placeholder="Flat/House no" 
                                 />

                            </div>
                            <div className='mt-3'>
                                <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="text"
                                 name="address"
                                 value={formData.address}
                                 onChange={handleChange} 
                                placeholder="Address"
                                />

                            </div>
                            <div className='flex justify-center gap-3 mt-3'>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="text"
                                     name='city'
                                     value={formData.city}
                                     onChange={handleChange}
                                     placeholder="City"
                                      />
                                </div>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="text"
                                                   name="state"
                                                   value={formData.state}
                                                   onChange={handleChange}
                                                   placeholder="State"
                                   
                                     />
                                </div>
                            </div>
                            <div className='flex justify-center gap-3 mt-3'>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="number" 
                                                    name="postalCode"
                                                    value={formData.postalCode}
                                                    onChange={handleChange}
                                                    placeholder="Postal Code"
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <input className="w-[100%] border border-[#e8e8eb] py-1 outline-none rounded-md text-black px-2" type="text" 
                                      name="landmark"
                                      value={formData.landmark}
                                      onChange={handleChange}
                                      placeholder="Landmark"
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between items-center'>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="mt-4 px-4 py-2 bg-[#dddddd] text-black rounded-full"
                                >
                                    Back
                                </button>

                                <button className="mt-4 px-4 py-2 bg-[#8ABE71] text-white rounded-full" onClick={handlePayment} >Pay Now</button>
                            </div>
                        </div>

                    </div>
                </div>
            )}
            <Footer />
        </div>
    )
}
