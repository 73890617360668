import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="footer-section footer-style2 z-1 position-relative blackbg">
        <div className="container">
          <div className="footer-social-logo d-flex align-items-center justify-content-sm-between justify-content-center">
            <a href="/">
              <img src="/Group 1597883311.png" style={{ height: '100px', width: '100px' }} alt="logo-img" />
            </a>
            <div className="social-wrapper d-flex align-items-center">
              <a href="https://www.facebook.com/profile.php?id=61567770061213" target='_blank' className=" black">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://www.linkedin.com/company/wyse-global-health/about/?viewAsMember=true" target='_blank' className=" black">
                <i className="fa-brands fa-linkedin-in" />
              </a>
              <a href="https://www.instagram.com/wysehealth/profilecard/?igsh=MXhsb2NqYzVwcHN0Ng==" target='_blank' className=" black">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://x.com/health_wys85810" target='_blank' className=" black">
                <i className="fa-brands fa-x" />
              </a>
            </div>
          </div>
          <div className="footer-widgets-wrapper">
            <div className="row g-4 justify-content-between">
              <div className="col-lg-2 col-md-6 col-sm-5 d-flex justify-content-lg-center">
                <div
                  className="single-footer-widget wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <div className="widget-head">
                    <h4 className="white  text-lg font-bold">Contact</h4>
                  </div>
                  <ul className="footer-info d-flex flex-column gpa-xxl-4 gap-3 ">
                    <li className="d-flex align-items-center gap-xl-3 gap-2">
                      <span className="icon d-center px-3">
                        <i className="p1-clr fa-solid fa-location-dot" />
                      </span>
                      <div className="cont white">
                        <p className=" fs-seven d-block">Address</p>
                        <p>
                          #43, Wework Galaxy, Residency Road, Bangalore 560025
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center gap-xl-3 gap-2">
                      <span className="icon d-center">
                        <i className="p1-clr fa-solid fa-phone" />
                      </span>
                      <div className="cont white ">
                        <p className="fs-seven d-block ">Phone Number</p>
                        <p>
                          7899802970
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center gap-xl-3 gap-2">
                      <span className="icon d-center">
                        <i className="p1-clr fa-solid fa-envelope" />
                      </span>
                      <div className="cont white">
                        <p className="fs-seven d-block ">Email</p>
                        <p>
                          info@wyse.health
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-1 col-md-6 col-sm-5 d-flex justify-content-lg-center">
                <div
                  className="single-footer-widget wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="widget-head">
                    <h4 className="white fw_600 text-lg font-bold">Pages</h4>
                  </div>
                  <ul className="list-area ">
                    <li>
                      <a className='' href="/"><i className="fa-solid fa-angles-right " />&nbsp;  Home</a>
                    </li>
                    <li>
                      <a className='' href="/aboutus"><i className="fa-solid fa-angles-right " />&nbsp; About</a>
                    </li>
                    <li>
                      <a className='' href="/business"><i className="fa-solid fa-angles-right " />&nbsp; Corporate</a>
                    </li>
                    <li>
                      <a className='' href="/practition"><i className="fa-solid fa-angles-right " />&nbsp; Practition</a>
                    </li>
                    <li>
                      <a className='' href="/blogs"><i className="fa-solid fa-angles-right " />&nbsp; Blogs</a>
                    </li>
                    <li>
                      <a className='' href="/faq"><i className="fa-solid fa-angles-right " />&nbsp; Faq</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 d-flex justify-content-lg-center">
                <div
                  className="single-footer-widget wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="widget-head">
                    <h4 className="white fw_600 text-lg font-bold">Shop</h4>
                  </div>
                  <ul className='list-area'>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Gut Health Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Brain Health Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Beauty Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Corporate Basic Health</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Corporate Health</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Corporate Premium Health</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 d-flex justify-content-lg-center">
                <div
                  className="single-footer-widget wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <ul className="list-area pt-12">
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Diabetes Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Micro Metabolic Pathways</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Sports Health Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Longevity Package</a>
                    </li>
                    <li>
                      <a href="#"><i className="fa-solid fa-angles-right" />&nbsp; Full Metabolic Pathways</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-7">
                <div
                  className="single-footer-widget wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="widget-head">
                    <h4 className="white fw_600 text-lg font-bold">Newsletter</h4>
                  </div>
                  <div className="footer-content">
                    <p className="white">
                      Medical services are an essential part of our lives, offering
                      care and treatment for various
                    </p>
                  </div>
                  <form action="index2.html#" className="form-cmn-style1">
                    <input type="text" className='placeholder:text-sm' placeholder="Enter your email" />
                    <button
                      type="button"
                      className="common-btn text-nowrap box-style first-box d-inline-flex justify-content-center align-items-center fs-seven fw_600 gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded-5"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="">
            <div className="footer-bottom-wrap">
              <p className="body-font fs-seven">
                © {new Date().getFullYear()} Wyse Health | All Rights Reserved
              </p>
              <ul className="privacy">
                <li>
                  <a href="javascript:void(0)" className="fs-seven">
                    Terms &amp; Condition
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="fs-seven">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="fs-seven">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

    </div>
  )
}

export default Footer