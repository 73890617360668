import React from 'react'

export default function Section9() {
    return (
        <div>
            <section className="helth-compassionate bg-[#090a0b]">
                <div className="container">
                    <div className="row g-4 items-center">
                        <div className="col-lg-7 h-100">
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="box ">
                                    <h4 className="text-white mb-md-3 mb-2 font-bold text-2xl">
                                        Personalized Genetic Insights for Your Future
                                    </h4>
                                    <p className="text-white mb-10">
                                        Genetic testing is a critical tool in ensuring well-being, offering a comprehensive range of services that empower you to take preventive measures based on your unique DNA...
                                    </p>
                                    <a
                                        href="#"
                                        className="border px-3 py-2 text-white box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold overflow-hidden rounded100"
                                    >
                                        Book An Appointment
                                        <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                                    </a>
                                </div>
                                <div>
                                    <img src="https://cdn.pixabay.com/photo/2024/02/07/15/09/ai-generated-8559288_1280.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 h-100">
                            <div className="compassionate-right bg-white space-y-2">
                                <div className="compassionate-item">
                                    <img src="assets/img/icon/points1.svg" alt="icon"/>
                                    <div className="cont">
                                        <h5 className="black mb-2 font-bold text-lg">Unlocking Your Genetic Blueprint</h5>
                                        <p className="black leading-5">
                                            DNA testing provides essential insights into your health, empowering you to make informed decisions based on your unique genetic makeup...
                                        </p>
                                    </div>
                                </div>
                                <div className="compassionate-item">
                                    <img src="assets/img/icon/points2.svg" alt="icon" />
                                    <div className="cont">
                                        <h5 className="black mb-2 font-bold text-lg leading-5">Genetic Wellness Tailored for You</h5>
                                        <p className="black leading-5">
                                            Understanding your DNA is key to achieving optimal wellness. Our services focus on genetic testing and analysis...
                                        </p>
                                    </div>
                                </div>
                                <div className="compassionate-item">
                                    <img src="assets/img/icon/points3.svg" alt="icon" />
                                    <div className="cont">
                                        <h5 className="black mb-2 font-bold text-lg  leading-5">Innovating Health Through Genetic Data</h5>
                                        <p className="black  leading-5">
                                            Medical care encompasses a range of services aimed at the a
                                            promoting health, preventing
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
