import React from 'react'

export default function Section4() {
    return (
        <section className="latest-projects fix cmn-bg" >
            <div className="container">
                <div className="d-flex flex-sm-nowrap flex-wrap gap-2 align-items-end justify-content-between mb-10">
                    <div
                        className="opacity-0 cursor-text array-button d-flex align-items-center gap-3 wow fadeInUp"
                        data-wow-delay=".5s"
                    >
                        <button className="cursor-text array-prev d-center">
                            <img src="assets/img/icon/arrow-left-black.png" alt="icon" />
                        </button>
                        <button className="cursor-text array-next d-center active">
                            <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                        </button>
                    </div>
                    <h2
                        className="wow fadeInUp black visible-slowly-right font-bold text-2xl md:text-4xl 2xl:text-5xl"
                        data-wow-delay=".3s"
                    >
                        Comprehensive DNA Testing
                        <br /> & Genetic Analysis Solutions
                    </h2>
                    <div
                        className="array-button d-flex align-items-center gap-3 wow fadeInUp"
                        data-wow-delay=".5s"
                    >
                        <button className="array-prev d-center">
                            <img src="assets/img/icon/arrow-left-black.png" alt="icon" />
                        </button>
                        <button className="array-next d-center active">
                            <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                        </button>
                    </div>
                </div>
                <div className="swiper lastes-project__wrapper">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide">
                            <div className="latest-project-slide">
                                <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_df3ec3d2-2dad-4a8b-8e07-f4752b2cea00.png" alt="img" />
                                <div className="ls-content text-center">
                                    <span className="white d-block mb-2 font-bold text-lg">Chronic Disease </span>
                                    <h4 className="white">
                                        <a href="#" className="white">
                                            Chronic diseases impact daily life and require ongoing management. Wyse Health provides holistic care through symptom management, emotional support, and functional assistance, aiming to improve quality of life for individuals living with long-term conditions.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="latest-project-slide">
                                <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_dimension_og_928bf610-5c69-40b4-b4cc-87cb2ca54114.png" alt="img" />
                                <div className="ls-content text-center">
                                    <span className="white d-block mb-2 font-bold text-lg">Longevity Panel</span>
                                    <h4 className="white">
                                        <a href="#" className="white">
                                            Unlock the secrets to a long and healthy life with a holistic approach to wellness. Focusing on healthy lifestyles, exercise, stress management, and social connections, we provide personalized care plans, health assessments, and expert guidance to promote longevity and vitality. Start your journey towards a vibrant life today.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="latest-project-slide">
                                <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_dimension_og_0fc2e572-6ce7-4017-afe2-e4837ecebe60.png" alt="img" />
                                <div className="ls-content text-center">
                                    <span className="white d-block mb-2 font-bold text-lg">Sports Panel</span>
                                    <h4 className="white">
                                        <a href="#" className="white">
                                            Maximize your athletic potential and enhance your well-being with a comprehensive approach to sports health. We focus on injury prevention, performance optimization, mental resilience, and recovery strategies, offering tailored training plans and expert guidance to help you stay at the top of your game. Reach your full potential and maintain a healthy, active lifestyle with our sports health solutions.
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
