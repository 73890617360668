import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight, faStar, faStarHalf, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import Footer from '../Layout/Footer';

export default function Blogs() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 -left-5 w-10 h-10 rounded-full cursor-pointer text-black"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 -right-5 w-10 h-10 rounded-full cursor-pointer text-black"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const blog_img = ['https://miro.medium.com/v2/resize:fit:720/format:webp/1*UOWAgrD6KpBbqOdJEF_JYQ.jpeg', 
                      'https://miro.medium.com/v2/resize:fit:720/format:webp/1*VT-NchnrijSTORzW6qnakg.jpeg',
                      'https://miro.medium.com/v2/resize:fit:720/format:webp/1*J2XrPph5YiEUoY9fYTgVTw.jpeg']

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setLoading(false);
    }, 2000);
    }, [])

    return (
        <div>
            <Header />
            <div className='w-[90%] mx-auto mb-10 2xl:mb-14'>
                <div className='flex flex-col lg:flex-row gap-7 lg:gap-0 mt-10'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <h1 className='font-bold text-3xl md:w-[90%] mx-auto'>The Role of DNA Insights in Revolutionizing Corporate Wellness
                        </h1>
                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <p className='mb-3'>Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.</p>
                        <a href='https://medium.com/@wyseglobalhealth' target='_blank' className='p-1 pt-0 font-semibold text-black' style={{ borderBottom: '1.5px solid black' }}>View Post</a>
                    </div>
                </div>
                <div className='mt-10'>
                    <Slider className='px-1 lg:px-3' {...settings} >
                        {loading ?
                            Array.from({ length: 3 }).map((_, index) => (
                                <div key={index} className="px-1 lg:px-3 animate-pulse">
                                    <div className="w-full h-60 bg-gray-300 shadow-md rounded-lg"></div>
                                </div>
                            )) :
                            blog_img.map((imgs, index) => (
                                <div key={index} className="px-1 lg:px-3">
                                    <img src={imgs} className="w-full h-52 rounded-lg">
                                    </img>
                                </div>
                            ))}
                    </Slider>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
