import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../Components/Pages/Home/Home';
import Aboutus from '../Components/Pages/Aboutus';
import Contact from '../Components/Pages/Contact';
import Shop from '../Components/Pages/Shop';
import Blogs from '../Components/Pages/Blogs';
import Business from '../Components/Pages/Business';
import Practition from '../Components/Pages/Practition';
import Faq from '../Components/Pages/Faq';
import Our_Panels from '../Components/Pages/Our_Panels';
import Loader from '../Components/Service/Loader';
import SuccessTransaction from '../Components/Pages/SuccessTransaction';



function route() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/practition" element={<Practition />} />
            <Route path="/business" element={<Business/>} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/ourpanels" element={<Our_Panels/>} />
            <Route path="/success" element={<SuccessTransaction/>} />


            <Route path="/Loader" element={<Loader/>} />

        </Routes>
    );
}

export default route;
