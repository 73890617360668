import React from 'react'

export default function Section7() {
    return (
        <div className='bg-black '>
            <div className='container flex flex-col lg:flex-row items-center justify-between gap-10'>
                <div className='w-[100%] lg:w-[40%] '>
                    <h1 className='text-white font-bold text-2xl md:text-4xl 2xl:text-5xl'> Take a look inside Wyse Health</h1>
                    <p className='text-white mt-2 leading-8'>Check out our Introductory Report, featuring a sample of the personalized health insights you'll receive with Wyse Health. With over 2.2+ million genetic tests done, we offer personalized insights to suit your unique health needs, you’ll get actionable data to guide your wellness journey.</p>
                    <a href='/shop'
                        className="mt-3 common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100"
                    >
                        Build your plan
                    </a>
                </div>
                <div className='w-[100%] lg:w-[50%] '>
                    <img src='/assets/img/element/laptop.png' className='laptop-size w-[75%] md:w-[80%] mx-auto'></img>
                </div>
            </div>
        </div>
    )
}