

import React, { useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

function Header() {

  const [isOpen, setOpen] = useState(false);

  const [openMenus, setOpenMenus] = useState({
    learn: false,
    shop: false,
    employee: false,
  });

  // Function to toggle each menu
  const toggleMenu = (menu) => {
    setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  return (
    <div>
      <div className='relative hidden lg:block'>
        <header id="header-sticky" className="header-1 bg-transparent sticky-header1 w-[100%]">
          <div className="w-[90%] mx-auto">
            <div className="mega-menu-wrapper">
              <div className="header-main style-2 lg:py-3">
                <div className="header-left">
                  <div className="logo ">
                    <a href="/" className="header-logo">
                      <img src="/assets/img/logo/WYSElogo.png" className='hidden md:block w-24' alt="logo-img" />
                      <img src="/wyse_logo_png.png" className='block md:hidden' style={{ height: '120px', width: '100px' }} alt="logo-img" />
                    </a>
                  </div>
                </div>
                <div className="header-right d-flex justify-content-end align-items-center">
                  <div className="mean__menu-wrapper">
                    <div className="main-menu">
                      <nav id="mobile-menu">
                        <ul>
                          <li className="has-dropdown active menu-thumb">
                            <a href="/">
                              Home
                              {/* <i className="fas fa-angle-down" /> */}
                            </a>
                            {/* <ul className="submenu has-homemenu">
                      <li>
                        <div className="homemenu-items">
                          <div className="homemenu">
                            <a href="index.html" className="homemenu-thumb">
                              <img
                                src="assets/img/header/home-1.jpg"
                                alt="img"
                              />
                              <span className="demo-button">
                                <span className="theme-btn p1-bg box-style first-box">
                                  <span className="black">Home 01</span>
                                </span>
                              </span>
                            </a>
                          </div>
                          <div className="homemenu">
                            <a
                              href="index2.html"
                              className="homemenu-thumb mb-15"
                            >
                              <img
                                src="assets/img/header/home-2.jpg"
                                alt="img"
                              />
                              <span className="demo-button">
                                <span className="theme-btn p1-bg box-style first-box">
                                  <span className="black">Home 02</span>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul> */}
                          </li>
                          <li>
                            <a>
                              Learn
                              <i className="fas fa-angle-down ms-1" />
                            </a>
                            <ul className="submenu">
                              <li>
                                <a href="/aboutus">About us</a>
                              </li>
                              <li>
                                <a href="/blogs">Blogs</a>
                              </li>
                              <li>
                                <a href="/faq">FAQ</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a>
                              Shop
                              <i className="fas fa-angle-down ms-1" />
                            </a>
                            <ul className="submenu">
                              <li>
                                <a href='/ourpanels'>Panels List</a>
                              </li>
                            </ul>
                          </li>
                          <li className="has-dropdown">
                            <a>
                              Employee
                              <i className="fas fa-angle-down ms-1" />
                            </a>
                            <ul className="submenu">
                              <li>
                                <a href="/business">Corporate</a>
                              </li>
                              <li>
                                <a href="/practition">Practitions</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  {/* <a
                  className="search-trigger search-icon d-xl-none d-block"
                >
                  <i className="fal fa-search" />
                </a> */}
                  <div className="header__hamburger d-xl-none my-auto">
                    <div className="sidebar__toggle">
                      <img src="assets/img/icon/menu.png" alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="header-btn d-xl-block d-none">
                  <a
                    className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100"
                  >
                    Build your plan
                    <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className='relative block lg:hidden'>
        <header id="header-sticky" className="header-1 bg-transparent sticky-header1 w-[90%] mx-auto">
          <div className="mega-menu-wrapper">
            <div className='header-main style-2'>
              <div className="header-left">
                <div className="logo ">
                  <a href="/" className="header-logo">
                    <img src="/assets/img/logo/WYSElogo.png" className='hidden md:block w-24' alt="logo-img" />
                    <img src="/wyse_logo_png.png" className='block md:hidden' style={{ height: '120px', width: '100px' }} alt="logo-img" />
                  </a>
                </div>
              </div>
              <div className='header-right'>
                <img src="assets/img/icon/menu.png" alt="icon" className='w-7' onClick={() => setOpen(true)} />
              </div>
            </div>
          </div>
        </header>
        {isOpen && (
          <div className="fixed inset-0 bg-black/80 flex justify-end" style={{ zIndex: 100 }}>
            <div data-aos='slide-left' data-aos-duration='1000' className="bg-gray-100 w-[75%] h-full p-3 grid content-between md:content-start space-y-0 md:space-y-10 overflow-y-auto">
              <div className="flex justify-between items-center">
                <img src="/assets/img/logo/WYSElogo.png" className="block lg:hidden w-20" alt="logo-img" />
                <button className="bg-black rounded-md w-8 h-8" onClick={() => setOpen(false)}>
                  <i className="fa-solid fa-xmark text-white"></i>
                </button>
              </div>

              <div>
                <ul className="space-y-3">
                  <li className="flex justify-between items-center border-b pb-1">
                    <a href="/" className="black">Home</a>
                  </li>
                  <div>
                    <li className="flex justify-between items-center border-b pb-1">
                      <a href="#" className="black">Learn</a>
                      <button onClick={() => toggleMenu("learn")}>
                        <i className={`fa-solid ${openMenus.learn ? "fa-minus" : "fa-plus"}`}></i>
                      </button>
                    </li>
                    <div className={`overflow-hidden transition-all duration-300 ${openMenus.learn ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                      }`}
                    >
                      <ul className="pl-4">
                        <li className='border-b py-2'><a href="/aboutus" className="text-gray-500">About us</a></li>
                        <li className='border-b py-2'><a href="/blogs" className="text-gray-500">Blogs</a></li>
                        <li className='border-b py-2'><a href="/faq" className="text-gray-500">FAQ</a></li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <li className="flex justify-between items-center border-b pb-1">
                      <a href="#" className="black">Shop</a>
                      <button onClick={() => toggleMenu("shop")}>
                        <i className={`fa-solid ${openMenus.shop ? "fa-minus" : "fa-plus"}`}></i>
                      </button>
                    </li>
                    <div className={`overflow-hidden transition-all duration-300 ${openMenus.shop ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                      }`}
                    >
                      <ul className="pl-4">
                        <li className='border-b py-2'><a href="/ourpanels" className="text-gray-800">Panels List</a></li>
                      </ul>
                    </div>
                  </div>
                  <li className="flex justify-between items-center border-b pb-1">
                    <a href="#" className="black">Employee</a>
                    <button onClick={() => toggleMenu("employee")}>
                      <i className={`fa-solid ${openMenus.employee ? "fa-minus" : "fa-plus"}`}></i>
                    </button>
                  </li>
                  <div className={`overflow-hidden transition-all duration-300 ${openMenus.employee ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                    }`}
                  >
                    <ul className="pl-4">
                      <li className='border-b py-2'><a href="/business" className="text-gray-800">Corporate</a></li>
                      <li className='border-b py-2'><a href="/practition" className="text-gray-800">Practitions</a></li>
                    </ul>
                  </div>
                </ul>
              </div>

              <div className="black space-y-2">
                <p className="font-semibold">Contact Info</p>
                <p><i className="fa-solid fa-location-dot mr-2"></i>#43, Wework Galaxy, Residency Road, Bangalore 560025</p>
                <p><i className="fa-solid fa-envelope mr-2"></i> info@wyse.health</p>
                <p><i className="fa-solid fa-clock mr-2"></i> Sun-Friday, 02am - 09pm</p>
                <p><i className="fa-solid fa-phone mr-2"></i> 7899802970</p>
                <button className="bg-[#84BE71] text-white px-4 py-2 rounded-full w-full mt-3 flex items-center justify-center">
                  Get A Quote &rarr;
                </button>
              </div>

              <div className="flex space-x-3 mt-4">
                <a href="https://www.facebook.com/profile.php?id=61567770061213" target='/blank'>
                  <button className='w-10 h-10 rounded-md bg-white border'>
                    <i className="fa-brands fa-facebook text-gray-600 text-xl"></i>
                  </button>
                </a>
                <a href="https://www.instagram.com/wysehealth/profilecard/?igsh=MXhsb2NqYzVwcHN0Ng==" target='_blank'>
                  <button className='w-10 h-10 rounded-md bg-white border'>
                    <i className="fa-brands fa-twitter text-gray-600 text-xl"></i>
                  </button>
                </a>
                <a href="https://x.com/health_wys85810" target='_blank'>
                  <button className='w-10 h-10 rounded-md bg-white border'>
                    <i className="fa-brands fa-instagram text-gray-600 text-xl"></i>
                  </button>
                </a>
                <a href="https://www.linkedin.com/company/wyse-global-health/about/?viewAsMember=true" target='/blank'>
                  <button className='w-10 h-10 rounded-md bg-white border'>
                    <i className="fa-brands fa-linkedin text-gray-600 text-xl"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header