const initialState = {
  user: null,
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  isAuthenticated: !!localStorage.getItem("accessToken"),
  loading: false,
  error: null,
  cartCount: 0, // New state for cart count
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isAuthenticated: true,
      };

    case "LOGOUT_USER":
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        user: null,
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        cartCount: 0, // Reset cart count on logout
      };

    case "SET_TOKENS":
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case "SET_CART_COUNT":
      return {
        ...state,
        cartCount: action.payload, // Update cart count
      };
      case "INCREMENT_CART_COUNT":
        return {
          ...state,
          cartCount: state.cartCount + 1, // Increment cart count
        };
  
      case "DECREMENT_CART_COUNT":
        return {
          ...state,
          cartCount: state.cartCount > 0 ? state.cartCount - 1 : 0, // Decrement cart count (ensure it doesn't go below 0)
        };

    default:
      return state;
  }
};

export default userReducer;
