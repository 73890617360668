import React, { useState } from 'react'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Marquee from "react-fast-marquee";
import '../../Styles/style.css';
import Section10 from './Section10';
import Section9 from './Section9';
import Section8 from './Section8';
import Section7 from './Section7';
import Section6 from './Section6';
import Section5 from './Section5';
import Section4 from './Section4';
import Section3 from './Section3';

function Home() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, subject, message } = formData;

    if (!name || !email || !phone || !subject || !message) {
      toast.error('All fields are required!');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    if (subject.length < 3) {
      toast.error('Subject must be at least 3 characters long.');
      return;
    }

    if (message.length < 10) {
      toast.error('Message must be at least 10 characters long.');
      return;
    }
    toast.loading('please wait while sending Message', {
      duration: 400
    })
    try {
      const response = await axios.post('https://wyse.health/api/form', formData);
      console.log(response);
      toast.success('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form. Please try again.');
    }
  };

  return (
    <div className="App overflow-hidden">
      <Header />
      <>
        <Toaster position='top-center' />

        <section className='DNA_video'>
          <div className='px-[1.5rem] md:px-[4.5rem] 2xl:px-[8rem] pt-10 2xl:pt-20 flex flex-col lg:flex-row justify-between '>
            <div className='w-[100%] lg:w-[35%] grid content-center'>
              <h1
                className="wow black  text-2xl md:text-4xl 2xl:text-5xl font-bold" style={{ zIndex: 1 }}
                data-wow-delay=".3s"
              >
                Discover the Power Within
                <span className="position-relative z-1 mb-40 text-2xl md:text-4xl 2xl:text-5xl ml-2">
                  Your DNA.
                  <img
                    src="assets/img/element/title-badge1.png"
                    alt="img"
                    className="title-badge1 d-md-block d-none w-100 translate-y-2"
                  />
                </span>
              </h1>
              <p className='leading-8 mt-3' style={{ zIndex: 1 }}>Decode the secrets to personalized <br /> wellness recommendations!</p>
              <div>
                <button className="relative group mt-4 bg-[#84BE71] text-black font-medium px-4 py-2 rounded-full overflow-hidden flex justify-center items-center">
                  <span className="relative z-10 duration-500 group-hover:text-white">
                    Build your plan
                  </span>
                  <span className="absolute inset-0 bg-black scale-0 transition-transform duration-500 group-hover:scale-100 rounded-full"></span>
                </button>
              </div>
            </div>
            <div className='w-[100%] lg:w-[40%] grid content-end'>
              <video src='/assets/video/Main Banner wyse loop.mp4' className='lg:scale-150 lg:-translate-x-10 lg:translate-y-10' autoPlay muted loop playsInline controls={false} ></video>
            </div>
            <div className='w-[100%] lg:w-[25%]'>
              <div className='rounded-2xl border backdrop-blur-sm shadow-md p-4'>
                <form onSubmit={handleSubmit} className="hero-appoinment">
                  <h4 className="font-bold text-2xl mb-2">Let’s Connect</h4>
                  <p className="pra mb-xxl-3 mb-2">
                    Unlock the power of your DNA for a healthier, informed life.
                  </p>
                  <input type="text" placeholder="Your Name" name='name' value={formData.name} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '')}
                    onChange={handleChange} className="bg-[#F5F6F8] w-[100%] py-2 px-3 rounded-full text-black mb-3" />
                  <input placeholder="Your Phone" name='phone' value={formData.phone} onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                    onChange={handleChange} className="bg-[#F5F6F8] w-[100%] py-2 px-3 rounded-full text-black mb-3" />
                  <input type="email" placeholder="Your Email" name='email' value={formData.email}
                    onChange={handleChange} className="bg-[#F5F6F8] w-[100%] py-2 px-3 rounded-full text-black mb-3" />
                  <input type="text" placeholder="Your Subject" name='subject' value={formData.subject}
                    onChange={handleChange} className="bg-[#F5F6F8] w-[100%] py-2 px-3 rounded-full text-black mb-3" />
                  <input type="text" placeholder="Your Message" name='message' value={formData.message}
                    onChange={handleChange} className="bg-[#F5F6F8] w-[100%] py-2 px-3 rounded-full text-black mb-3" />
                  <button
                    type='submit'
                    className="common-btn box-style p2-bg w-100 text-nowrap d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs-seven fw-medium white overflow-hidden rounded100 wow fadeInRight"
                    data-wow-delay="0.8s"
                  >
                    Get in Touch
                    <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <Marquee className='bg-black py-7'>
            <div className='flex items-center justify-between gap-12 2xl:gap-20'>
              <img src='/assets/img/banner/marquee1.svg'></img>
              <img src='/assets/img/banner/marquee2.svg'></img>
              <img src='/assets/img/banner/marquee3.svg'></img>
              <img src='/assets/img/banner/marquee1.svg'></img>
              <img src='/assets/img/banner/marquee2.svg'></img>
              <img src='/assets/img/banner/marquee3.svg'></img>
              <img src='/assets/img/banner/marquee1.svg'></img>
              <img src='/assets/img/banner/marquee2.svg'></img>
              <img src='/assets/img/banner/marquee3.svg'></img>
            </div>
          </Marquee>
        </section>
        {/* Servie Section Start */}
        <section className="services-section fix">
          <div className="container">
            <div className="section-title text-center mb-10">
              <h1
                className="wow fadeInUp black visible-slowly-right text-2xl md:text-4xl 2xl:text-5xl font-bold"
                data-wow-delay=".3s"
              >
                Why Choose
                <span className="position-relative z-1 mb-40 text-2xl md:text-4xl 2xl:text-5xl ml-2">
                  WYSE HEALTH?
                  <img
                    src="assets/img/element/title-badge1.png"
                    alt="img"
                    className="title-badge1 d-md-block d-none w-100 "
                  />
                </span>
              </h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-4 col-md-6">
                <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
                  <div className="icon-area d-flex align-items-center gap-2">
                    <div className="icon d-center">
                      <img src="assets/img/icon/ser1.png" alt="icon" />
                    </div>
                    <h4 className="black font-bold text-lg leading-5">
                      <a href="#">Comprehensive Genetic Testing</a>
                    </h4>
                  </div>
                  <p className="pra">
                    Explore insights into your genetic health, helping
                    you make informed decisions about your well-
                    being and lifestyle.
                  </p>
                  <div className="thumb overflow-hidden reveal-left position-relative">
                    <img
                      src="https://photos.peopleimages.com/picture/202302/2630497-science-petri-dish-and-man-scientist-for-healthcare-research-test-analysis-or-neurology-study-in-laboratory.-focus-medical-worker-chemistry-professional-or-expert-with-blood-dna-sample-for-cancer-fit_400_400.jpg"
                      alt="img"
                      className="rounded-4 overflow-hidden w-100"
                    />
                  </div>
                  <div className="text-center service-btn-inner">
                    <a
                      href="#"
                      className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
                    >
                      Learn More
                      <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
                  <div className="icon-area d-flex align-items-center gap-2">
                    <div className="icon d-center">
                      <img src="assets/img/icon/ser2.png" alt="icon" />
                    </div>
                    <h4 className="black font-bold text-lg leading-5">
                      <a href="#">Personalized Health
                        Plans</a>
                    </h4>
                  </div>
                  <p className="pra">
                    Get custom health and wellness plans based on
                    your genetic makeup, tailored to optimize your
                    lifestyle.
                  </p>
                  <div className="thumb overflow-hidden reveal-left position-relative">
                    <img
                      src="https://media.licdn.com/dms/image/D5612AQF33wtEWs78eA/article-cover_image-shrink_720_1280/0/1696180341787?e=2147483647&v=beta&t=_ANEdEVIL1J9DxaBPW4m-YAZFEqbTg3R8G9l98-iMYI"
                      alt="img"
                      className="rounded-4 overflow-hidden w-100"
                    />
                  </div>
                  <div className="text-center service-btn-inner">
                    <a
                      href="#"
                      className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
                    >
                      Learn More
                      <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item overflow-hidden white-bg rounded-4 position-relative">
                  <div className="icon-area d-flex align-items-center gap-2">
                    <div className="icon d-center">
                      <img src="assets/img/icon/ser3.png" alt="icon" />
                    </div>
                    <h4 className="black font-bold text-lg leading-5">
                      <a href="#">Advanced Health
                        Insights</a>
                    </h4>
                  </div>
                  <p className="pra">
                    Access cutting-edge genetic data to unlock
                    actionable insights for preventative care and
                    wellness.
                  </p>
                  <div className="thumb overflow-hidden reveal-left position-relative">
                    <img
                      src="https://media.istockphoto.com/id/1012405368/photo/scientists-in-laboratory-working-on-research.jpg?s=612x612&w=0&k=20&c=qGuh-kPQ2EBLU1dHZ2aMv5CDAt4pv6uTr-oRdOZO2Ck="
                      alt="img"
                      className="rounded-4 overflow-hidden w-100"
                    />
                  </div>
                  <div className="text-center service-btn-inner">
                    <a
                      href="#"
                      className="common-btn box-style first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden white-bg rounded100"
                    >
                      Learn More
                      <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Live a life */}
        <Section3 />

        {/* Latest Project Section Start */}
        <Section4 />

        {/* The world’s first */}
        <Section5 />

        {/*Pharmacogenomics*/}
        <Section6 />

        {/* Take a look inside */}
        <Section7 />

        {/*4 Simple Steps */}
        <Section8 />

        {/* Helth Compassionate */}
        <Section9 />

        {/* Video Bg Section Start */}
        <Section10 />
      </>

      <Footer />
    </div >
  )
}

export default Home


{/* Pricing Section Start */ }
//         <section className="pricing-section fix n900-bg space-top">
//           <div className="container">
//             <div className="section-title text-center mb-60">
//               <span className="cmn-tag p1-bg heading-font">Pricing Plans</span>
//               <h2 className="wow fadeInUp white visible-slowly-right" data-wow-delay=".3s">
//                 Where genetics meets insight <br /> partner in
//                 <span className="position-relative z-1">
//                   wellness
//                   <img src="assets/img/element/title-badge1.png" alt="img" className="title-badge1 d-md-block d-none w-100" />
//                 </span>
//               </h2>
//             </div>
//             <div className="row g-xxl-5 g-4 justify-content-center align-items-center">
//               <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
//                 <div className="pricing-items">
//                   <div className="pricing-head">
//                     <h4 className="white mb-2">Corporate Health</h4>
//                   </div>
//                   <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Discover your ethnic origins
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Connect with distant relatives
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Access family tree insights
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Explore genetic traits
//                     </li>
//                   </ul>
//                   <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
//                     Book An Appointment
//                     <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//                   </a>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
//                 <div className="pricing-items premium">
//                   <div className="pricing-head">
//                     <h4 className="white mb-2">Chronic Disease</h4>
//                   </div>
//                   <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Assess your risk for certain diseases
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Personalized lifestyle recommendations
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Preventive health strategies
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Regular monitoring for proactive health
//                     </li>
//                   </ul>
//                   <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
//                     Book An Appointment
//                     <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//                   </a>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
//                 <div className="pricing-items">
//                   <div className="pricing-head">
//                     <h4 className="white mb-2">Longevity</h4>
//                   </div>
//                   <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Guidance for family planning
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
//                     </li>
//                   </ul>
//                   <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
//                     Book An Appointment
//                     <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//                   </a>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
//                 <div className="pricing-items">
//                   <div className="pricing-head">
//                     <h4 className="white mb-2">Sports</h4>
//                   </div>
//                   <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Guidance for family planning
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
//                     </li>
//                   </ul>
//                   <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
//                     Book An Appointment
//                     <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//                   </a>
//                 </div>
//               </div>
//               <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
//                 <div className="pricing-items">
//                   <div className="pricing-head">
//                     <h4 className="white mb-2">Beauty </h4>
//                   </div>
//                   <ul className="pricing-list d-flex flex-column mb-4 pb-xl-3">
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Identify genetic conditions you may pass on to your children
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Inform reproductive choices
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Guidance for family planning
//                     </li>
//                     <li className="d-flex align-items-center gap-lg-2 gap-1 fs-six white">
//                       <i className="fa-solid fa-angles-right white" /> Genetic counseling for informed decisions
//                     </li>
//                   </ul>
//                   <a href="/Contact" className="common-btn box-style w-100 first-box d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold white overflow-hidden n900-bg rounded100">
//                     Book An Appointment
//                     <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

{/* Feature Section Start */ }

// <section className="feature-section fix section-padding">
// <div className="container">
//   <div className="section-title text-center mb-60">
//     <span className="cmn-tag p1-bg heading-font">Our Services</span>
//     <h2 className="wow fadeInUp black visible-slowly-right" data-wow-delay=".3s">
//       Comprehensive DNA Testing <br />
//       & Genetic Analysis
//       <span className="position-relative z-1">
//         Solutions
//         <img src="assets/img/element/title-badge1.png" alt="img" className="title-badge1 d-md-block d-none w-100" />
//       </span>
//     </h2>
//   </div>
//   <div className="featue-wrapper feaure-wrapper2 position-relative white-bg">
//     <div className="feature-inner">
//       <div className="ins d-flex flex-column gap-xxl-4 gap-3">
//         <div className="line" />
//         <div className="feature-items">
//           <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
//             <h4 className="black fw_600 text-nowrap">
//               <a href="#" className="black fw_600 text-nowrap">
//                 Corporate Health
//               </a>
//             </h4>
//             <ul className="feature-list d-flex flex-column gap-1">
//               <li className="text-nowrap">Discover your genetic roots</li>
//             </ul>
//             <p className="pra fs-seven">
//               Trace your ancestry back thousands of years, exploring your family’s migration patterns and unique genetic heritage.
//             </p>
//           </div>
//           <a href="#" className="cmn-arrows d-center">
//             <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
//           </a>
//           <img src="assets/img/choose/feature4.jpg" alt="img" className="extra-feature" />
//         </div>
//         <div className="line" />
//       </div>
//       <div className="ins d-flex flex-column gap-xxl-4 gap-3">
//         <div className="line" />
//         <div className="feature-items">
//           <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
//             <h4 className="black fw_600 text-nowrap">
//               <a href="#" className="black fw_600 text-nowrap">
//                 Chronic Disease
//               </a>
//             </h4>
//             <ul className="feature-list d-flex flex-column gap-1">
//               <li className="text-nowrap">Predict health risks</li>
//             </ul>
//             <p className="pra fs-seven">
//               Assess your genetic predisposition to various health conditions, empowering you to take proactive steps toward wellness.
//             </p>
//           </div>
//           <a href="#" className="cmn-arrows d-center">
//             <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
//           </a>
//           <img src="assets/img/choose/feature1.jpg" alt="img" className="extra-feature" />
//         </div>
//         <div className="line" />
//       </div>
//       <div className="ins d-flex flex-column gap-xxl-4 gap-3">
//         <div className="line" />
//         <div className="feature-items">
//           <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
//             <h4 className="black fw_600 text-nowrap">
//               <a href="#" className="black fw_600 text-nowrap">
//                 Longevity
//               </a>
//             </h4>
//             <ul className="feature-list d-flex flex-column gap-1">
//               <li className="text-nowrap">Personalized nutrition</li>
//             </ul>
//             <p className="pra fs-seven">
//               Learn how your genes affect your nutrition needs, enabling a personalized approach to your diet for optimal health.
//             </p>
//           </div>
//           <a href="#" className="cmn-arrows d-center">
//             <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
//           </a>
//           <img src="assets/img/choose/feature2.jpg" alt="img" className="extra-feature" />
//         </div>
//         <div className="line" />
//       </div>
//       <div className="ins d-flex flex-column gap-xxl-4 gap-3">
//         <div className="line" />
//         <div className="feature-items">
//           <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
//             <h4 className="black fw_600 text-nowrap">
//               <a href="#" className="black fw_600 text-nowrap">
//                 Sports
//               </a>
//             </h4>
//             <ul className="feature-list d-flex flex-column gap-1">
//               <li className="text-nowrap">Personalized medicine</li>
//             </ul>
//             <p className="pra fs-seven">
//               Understand how your genes influence your response to medications, allowing for safer and more effective treatments.
//             </p>
//           </div>
//           <a href="#" className="cmn-arrows d-center">
//             <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
//           </a>
//           <img src="assets/img/choose/feature3.jpg" alt="img" className="extra-feature" />
//         </div>
//         <div className="line" />
//       </div>
//       <div className="ins d-flex flex-column gap-xxl-4 gap-3">
//         <div className="line" />
//         <div className="feature-items">
//           <div className="feature-left d-lg-flex d-grid gap-3 flex-lg-nowrap flex-wrap justify-content-between align-items-center">
//             <h4 className="black fw_600 text-nowrap">
//               <a href="#" className="black fw_600 text-nowrap">
//                 Beauty
//               </a>
//             </h4>
//             <ul className="feature-list d-flex flex-column gap-1">
//               <li className="text-nowrap">Personalized medicine</li>
//             </ul>
//             <p className="pra fs-seven">
//               Understand how your genes influence your response to medications, allowing for safer and more effective treatments.
//             </p>
//           </div>
//           <a href="#" className="cmn-arrows d-center">
//             <img src="assets/img/icon/arrow-right-black.png" alt="icon" />
//           </a>
//           <img src="assets/img/choose/feature3.jpg" alt="img" className="extra-feature" />
//         </div>
//         <div className="line" />
//       </div>
//     </div>
//   </div>
// </div>
// </section>

{/*Section1*/ }
// <section className="banner-section2 white-bg fix">
// <div className="container">
//   <div className="row justify-content-between">
//     <div className="col-xxl-5 col-xl-5 col-lg-5">
//       <div className="hero-content-version2">

//         <h1 className="black wow fadeInUp" data-wow-delay="0.6s">
//           Discover the  <br />
//           <span className="position-relative z-1 w-100">
//             Power Within Your DNA.
//             <img
//               src="assets/img/element/title-badge1.png"
//               alt="img"
//               className="title-badge1 d-md-block d-none w-100"
//             />
//           </span>
//           {/* <span className="fw-normal">Personalized Health Insights</span> */}
//         </h1>
//         <div className="d-flex align-items-center gap-xl-2 gap-1">

//           <span className="fs-six pra " style={{ cursor: "pointer" }}>Learn More</span>
//         </div>
//         <div className="hero2-counter-wrap">
//           <div className="hero-count-item ">
//             <h2 className="black">2.2 Mil+</h2>
//             <p className="black">
//               Genetic  Tests accomplished
//             </p>
//           </div>
//           <div className="hero-count-item ">
//             <h2 className="black">80 Mil+</h2>
//             <p className="black">
//               Polymorphisms & Mutations Analyzed
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="col-xl-4 col-xl-2 col-lg-3 d-lg-block d-none">
//       <div className="hero2-thumb">
//         <img src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_5e677072-b4d2-427f-904e-0c5453cacc45 (3).jpg" alt="DNA Image" />
//       </div>
//     </div>
//     <div className="col-xxl-3 col-xl-4 col-lg-4">
//       <form onSubmit={handleSubmit} className="hero-appoinment">
//         <h4 className="black mb-xxl-3 mb-2">Let’s Connect</h4>
//         <p className="pra mb-xxl-3 mb-2">
//           Unlock the power of your DNA for a healthier, informed life.
//         </p>
//         <input type="text" placeholder="Your Name" name='name' value={formData.name}
//           onChange={handleChange} className="mb-3" />
//         <input type="text" placeholder="Your Phone" name='phone' value={formData.phone}
//           onChange={handleChange} className="mb-3" />
//         <input type="email" placeholder="Your Email" name='email' value={formData.email}
//           onChange={handleChange} className="mb-3" />
//         <input type="text" placeholder="Your Subject" name='subject' value={formData.subject}
//           onChange={handleChange} className="mb-3" />
//         <input type="text" placeholder="Your Message" name='message' value={formData.message}
//           onChange={handleChange} className="mb-3" />

//         {/* <select name="selected" className="mb-3">
//     <option value={1}>Select Service</option>
//     <option value={1}>Health Risk Analysis</option>
//     <option value={1}>Ancestry Test</option>
//     <option value={1}>Personalized Diet Plan</option>
//   </select> */}
//         <button
//           type='submit'
//           className="common-btn box-style p2-bg w-100 text-nowrap d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs-seven fw-medium white overflow-hidden rounded100 wow fadeInRight"
//           data-wow-delay="0.8s"
//         >
//           Get in Touch
//           <img src="assets/img/icon/arrow-right-white.png" alt="icon" />
//         </button>
//       </form>
//     </div>
//   </div>
// </div>
// </section>