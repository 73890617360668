import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight, faChevronDown, faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import Header from '../Layout/Header'
import Footer from "../Layout/Footer";

export default function Faq() {

  const faqData = [
    {
      question: "How does DNA testing work at Wyse Health?",
      answer: (
        <>
          Wyse Health’s DNA testing is simple and efficient: <br />
          <ul className="list-disc pl-5">
            <li>Provide a quick saliva sample.</li>
            <li>
              Our advanced genomic analysis provides key health insights using
              AI-driven big data analytics.
            </li>
            <li>
              Receive a personalized health report with recommendations tailored
              to your genetic profile.
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "Is my DNA data safe and secure? Or How does Wyse Health ensure patient privacy and data security?",
      answer: (
        <>
          Yes, your DNA data is protected with industry-leading security measures:
          <br />
          <ul className="list-disc pl-5">
            <li>End-to-End Encryption ensures data confidentiality.</li>
            <li>
              No Third-Party Sharing policy—your data is never shared without
              consent.
            </li>
            <li>
              Secure MedGen Portal provides confidential access to your reports.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "How long does it take to receive my results?",
      answer: (
        <>
          You will receive your personalized health insights within 21 days from
          sample submission. Results can be securely accessed through MedGen, Wyse
          Health’s online portal.
        </>
      ),
    },
    {
      question: "What makes Wyse Health different from other healthcare providers?",
      answer: (
        <>
          Wyse Health stands out with:
          <br />
          <ul className="list-disc pl-5">
            <li>2.2 Million Genomic Tests conducted for deep genetic insights.</li>
            <li>Big Data & AI-Driven Analysis for actionable health recommendations.</li>
            <li>
              Tailored, science-backed solutions designed for proactive health
              management.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I access my medical records online?",
      answer: (
        <>
          Yes! You can access your records anytime via MedGen, Wyse Health’s secure
          online portal:
          <br />
          <ul className="list-disc pl-5">
            <li>View test results and reports 24/7.</li>
            <li>Track and manage your health insights over time.</li>
            <li>Receive expert recommendations tailored to your DNA profile.</li>
          </ul>
        </>
      ),
    },
  ];

  const [expandedIndices, setExpandedIndices] = useState(
    Array(faqData.length).fill(false)
  );

  const toggleExpand = (index) => {
    setExpandedIndices(
      expandedIndices.map((item, i) => (i === index ? !item : item))
    );
  };
  return (
    <div>
      <Header />

      <div className='flex justify-center mb-3 lg:mb-0'>
        <div className='flex justify-center items-center w-[70%] lg:gap-10'>
          <img className="w-2/12 " src='/assets/img/element/Faq.svg'></img>
          <h1 className='font-bold text-2xl lg:text-4xl 2xl:text-5xl'>Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="w-[90%] md:w-[80%] mx-auto text-black pb-10 space-y-3">

        {faqData.map((faq, index) => (
          <div
            key={index}
            className={`text-black bg-[#f4f4f4] cursor-pointer rounded-md duration-500`}
          >
            <div
              onClick={() => toggleExpand(index)}
              className="bg-white/20 backdrop-blur-sm rounded-md"
            >
              <div
                className={`flex rounded-t-md duration-500 transform transition ease-in-out ${expandedIndices[index] ? "h-full" : "h-full"
                  }`}
              >
                <div className="w-[10%] flex items-center justify-center">
                  <button
                    className={`font-bold w-full h-full rounded-sm ${expandedIndices[index]
                      ? " bg-[#f4f4f4]"
                      : "bg-[#f4f4f4]"
                      }`}
                  >
                    <FontAwesomeIcon
                      icon={expandedIndices[index] ? faChevronDown : faChevronRight}
                      className={`transform transition-transform duration-500  ${expandedIndices[index]
                        ? " text-[#b7b7b7] text-sm font-bold duration-500 lg:text-2xl"
                        : "text-[#b7b7b7]  text-sm font-bold duration-500 lg:text-2xl"
                        }`}
                    />
                  </button>
                </div>
                <div className="w-[90%]">
                  <h1 className="font-bold text-sm lg:text-lg p-3">{faq.question}</h1>
                </div>

              </div>
              <div
                className={`duration-500 ease-in-out px-5 overflow-hidden ${expandedIndices[index]
                  ? "pb-3 max-h-[300px] rounded-b-md"
                  : "max-h-0"
                  }`}
              >
                <p className="text-sm lg:text-lg text-black duration-500">
                  {faq.answer}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  )
}
