import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './Routes/route';
import { Provider } from 'react-redux';
import { Toaster } from "react-hot-toast";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Redux/store';
import { CartProvider } from './Config/CartContext';
import { CurrencyProvider } from './Config/CurrencyContext'; // Import the CurrencyProvider
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <CartProvider>
      <CurrencyProvider> {/* Use CurrencyProvider here */}
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
            {/* Wrap your Routers component in BrowserRouter */}
            <Router>
              <Routers />
            </Router>
          </React.StrictMode>
        </PersistGate>
      </CurrencyProvider> {/* Closing CurrencyProvider */}
    </CartProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
