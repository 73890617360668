import React from 'react'

export default function Section8() {
    return (
        <div className='container'>
            <div className='text-center mt-5 lg:mt-0'>
                <h1 className='text-2xl md:text-4xl 2xl:text-5xl wow fadeInUp font-bold' data-wow-delay="0.6s">4 Simple
                    <span className="position-relative z-1 ml-2 text-2xl md:text-4xl 2xl:text-5xl">
                        Steps
                        <img
                            src="assets/img/element/title-badge1.png"
                            alt="img"
                            className="title-badge1 d-md-block d-none w-100"
                        />
                    </span>
                </h1>
                <h2 className='text-2xl mt-3'> To Better Health with Wyse Health</h2>
                <h3 className='mt-2'>Achieve personalized health insights and wellness with just four easy steps!</h3>
            </div>
            <div className='pb-[4.5rem]'>
                <div className='grid md:grid-cols-2 lg:grid-cols-4 relative gap-5 mt-10' style={{ zIndex: 1 }}>
                    <div className='shadow-md lg:shadow-none p-3 p-lg-0 rounded-lg lg:rounded-none text-center lg:mt-[250px] grid content-end lg:content-start'>
                        <video src='/assets/video/Wyse Simple step 01.mp4' autoPlay muted loop playsInline controls={false} className='w-20 2xl:w-28 mb-2 mx-auto rounded-full'></video>
                        <p className='text-black font-medium text-lg'>Sample Collection</p>
                        <p className='mt-1 mb-3'>Quick, simple, and painless saliva sample collection.</p>
                        <div className='relative flex items-center justify-center'>
                            <img src='/assets/img/icon/Rectangle.svg' className='z-0 relative mx-auto'></img>
                            <p className='absolute font-bold text-white text-3xl' style={{ zIndex: 1 }}>Step 1</p>
                        </div>
                    </div>
                    <div className='shadow-md lg:shadow-none p-3 p-lg-0 rounded-lg lg:rounded-none text-center lg:mt-[150px] arrow_missplace-step2 grid content-end lg:content-start'>
                        <video src='/assets/video/Wyse Simple step 02.mp4' autoPlay muted loop playsInline controls={false} className='w-20 2xl:w-28 mb-2 mx-auto rounded-full'></video>
                        <p className='text-black font-medium text-lg'>Lab Analysis & Report</p>
                        <p className='mt-1 mb-3'>Advanced genomic testing with blockchain-secured storage for your results, ensuring complete privacy and data integrity.</p>
                        <div className='relative flex items-center justify-center'>
                            <img src='/assets/img/icon/Rectangle.svg' className='z-0 relative mx-auto'></img>
                            <p className='absolute font-bold text-white text-3xl' style={{ zIndex: 1 }}>Step 2</p>
                        </div>
                    </div>
                    <div className='shadow-md lg:shadow-none p-3 p-lg-0 rounded-lg lg:rounded-none text-center lg:mt-[100px] arrow_missplace-step3 grid content-end lg:content-start'>
                        <video src='/assets/video/Wyse Simple step 03.mp4' autoPlay muted loop playsInline controls={false} className='w-20 2xl:w-28 mb-2 mx-auto rounded-full'></video>
                        <p className='text-black font-medium text-lg'>Consultation</p>
                        <p className='mt-1 mb-[25px]'>Expert consultation to discuss your results and health recommendations.</p>
                        <div className='relative flex items-center justify-center'>
                            <img src='/assets/img/icon/Rectangle2.svg' className='z-0 relative mx-auto'></img>
                            <p className='absolute font-bold text-white text-3xl' style={{ zIndex: 1 }}>Step 3</p>
                        </div>
                    </div>
                    <div className='shadow-md lg:shadow-none p-3 p-lg-0 rounded-lg lg:rounded-none text-center lg:mt-[30px] grid content-end lg:content-start'>
                        <video src='/assets/video/Wyse Simple step 04.mp4' autoPlay muted loop playsInline controls={false} className='w-20 2xl:w-28 mb-2 mx-auto rounded-full'></video>
                        <p className='text-black font-medium text-lg'>Personalized Care Plan</p>
                        <p className='mt-1 mb-3'>Tailored nutrition, lifestyle, and wellness plan powered by Genetic GPT, optimizing your health based on cutting-edge AI insights.</p>
                        <div className='relative flex items-center justify-center'>
                            <img src='/assets/img/icon/Rectangle3.svg' className='z-0 relative mx-auto'></img>
                            <p className='absolute font-bold text-white text-3xl' style={{ zIndex: 1 }}>Step 4</p>
                        </div>
                    </div>
                </div>
                <div className='relative hidden lg:block '>
                    <img src='/assets/img/icon/Ellipse1.svg' className='w-[25%] absolute -top-[3.5rem] 2xl:-top-[5rem] left-[10%] z-0'></img>
                    <img src='/assets/img/icon/Ellipse2.svg' className='w-[25%] absolute -rotate-3 -top-[7.5rem] 2xl:-top-[9.5rem] left-[50%] -translate-x-[50%] z-0'></img>
                    <img src='/assets/img/icon/Ellipse3.svg' className='w-[25%] absolute -top-[11rem] 2xl:-top-[13rem] right-[10%] z-0'></img>
                </div>
            </div>
        </div>
    )
}