import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

export default function Aboutus() {
  return (
    <div>
      <Header />
      <div className='Section-1 w-[90%] mx-auto flex flex-col lg:flex-row gap-10 items-center mt-10'>
        <div className='w-[100%] lg:w-[50%]'>
          <p className='leading-9'>At <span className='uppercase font-medium text-lg text-black'>Wyse Health,</span> we’re changing the way you approach your health by offering personalized recommendations based on your unique DNA, lab results, and lifestyle. Our goal is to empower you with the insights you need to make smarter, healthier choices every day.</p>
          <a className="mt-4 common-btn box-style first-box px-5 d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100">
            Contact Us
          </a>
        </div>
        <div className='w-[100%] lg:w-[50%] relative'>
          <img src='/assets/img/service/aboutus1.png' className='w-[75%] mt-4'></img>
          <img src='/assets/img/service/AU1.svg' className='absolute border rounded-full p-2 right-[25%] -top-7 lg:-top-12 w-12 lg:w-24'></img>
          <img src='/assets/img/service/AU2.svg' className='absolute border rounded-full p-2 right-[7%] top-5 w-12 lg:w-24'></img>
          <img src='/assets/img/service/AU3.svg' className='absolute border rounded-full p-2 right-[0%] top-[30%] w-12 lg:w-24'></img>
        </div>
      </div>
      <div className='Section-2 py-[4rem] bg-[#F9FFF6] mt-10'>
        <div className='w-[90%] mx-auto flex flex-col lg:flex-row gap-10 items-center '>
          <div className='w-[100%] lg:w-[50%]'>
            <h1 className='text-3xl font-bold'>Why Wyse Health?</h1>
            <p className='mt-3 bg-white p-3 rounded-xl shadow-sm'>Customized for You: No more one-size-fits-all advice. We provide health recommendations that are as unique as your DNA.</p>
            <p className='mt-3 bg-white p-3 rounded-xl shadow-sm'>Comprehensive Approach: We consider your genetics, environment, and daily habits to give you a complete picture of your health.</p>
            <p className='mt-3 bg-white p-3 rounded-xl shadow-sm'>Data-Driven Precision: Powered by Gen AI, our technology analyzes millions of genetic markers to deliver accurate, meaningful health insights.</p>
          </div>
          <div className='w-[100%] lg:w-[50%]'>
            <img src='/assets/img/service/abouts2.svg' className='w-[70%] mx-auto'></img>
          </div>
        </div>
      </div>
      <div className='Section-3 bg-black   md:min-h-screen py-[4rem] md:overflow-hidden flex flex-col'>
        <h1 className='font-bold text-3xl text-white text-center'>What Makes Us Different?</h1>
        <div className='relative flex  flex-col md:flex-row flex-1 justify-center items-center'>
          <div className='md:absolute inset-0 flex flex-col lg:flex-row md:gap-5 w-[90%] justify-between mx-auto' style={{ zIndex: 1 }}>
            <div className='w-[100%] lg:w-1/3 grid content-center gap-10 lg:gap-20'>
              <p className='text-white leading-8'><span className='font-bold text-lg mr-1'>Advanced Gen AI Technology:</span> Our cutting-edge Gen AI sifts through complex genetic data to provide clear, actionable health advice. This ensures that every recommendation is specifically designed for your needs.</p>
              <p className='text-white leading-8'><span className='font-bold text-lg mr-1'>Comprehensive Health Analysis:</span> We combine insights from your DNA, lab tests, and environment to give you a full understanding of your health.</p>
            </div>
            <div className='block md:hidden'>
              <video src='/assets/video/WYSE loop nw01 edited.mp4' className='w-[50%] mx-auto mt-5' autoPlay muted loop playsInline controls={false} ></video>
              <img src='/assets/img/element/about_video.png' className='-translate-y-5 mx-auto'></img>
            </div>
            {/*
          <div className='w-[90%] lg:w-[30%] 2xl:w-[25%] relative mx-auto lg:mx-0 grid content-center'>
           <img src='/assets/img/service/DNA.svg' className='w-[70%] mx-auto relative' style={{ zIndex: 1 }}></img>
            <img src='/assets/img/service/DNA1.svg' className='absolute top-[30%] -left-7 z-0'></img>
            <img src='/assets/img/service/DNA2.svg' className='absolute bottom-[25%] -left-12 z-0'></img>
            <img src='/assets/img/service/DNA3.svg' className='absolute top-[30%] -right-5 z-0'></img>
            <img src='/assets/img/service/DNA4.svg' className='absolute bottom-[30%] -right-12 z-0'></img>
          </div>*/}
            <div className='w-[100%] lg:w-1/3 grid content-center gap-10 lg:gap-20'>
              <p className='text-white leading-8'><span className='font-bold text-lg mr-1'>Expert-Backed Solutions:</span> Our experienced team of healthcare professionals ensures that every recommendation is scientifically sound and easy to understand.</p>
              <p className='text-white leading-8'><span className='font-bold text-lg mr-1'>Blockchain Security:</span> Your data is protected with blockchain technology, ensuring that your personal health information is secure and transparent.</p>
            </div>
          </div>
          <div className='z-0 translate-y-[72px] hidden md:block'>
            <video src='/assets/video/WYSE loop nw01 edited.mp4' className='w-[50%] mx-auto' autoPlay muted loop playsInline controls={false} ></video>
            <img src='/assets/img/element/about_video.png' className='w-[90%] mx-auto -translate-y-[72px]'></img>
          </div>
        </div>
      </div>
      <div className='Section-4'>
        <div className='w-[90%] mx-auto flex flex-col lg:flex-row gap-10 items-center mt-10'>
          <div className='w-[100%] lg:w-[50%]'>
            <h1 className='text-3xl font-bold'>Your Journey to Better Health Starts Here</h1>
            <p className='leading-8 mt-3'>With <span className='uppercase font-medium text-lg text-black'>Wyse Health,</span> you’re not just getting health advice you’re gaining a partner in your wellness journey. Our user-friendly reports and personalized insights help you take control of your health, one step at a time.
              <br /><br />
              Discover the Power of Personalized Health with Wyse Health<br />
              Take charge of your health today with insights and recommendations that are made just for you.</p>
          </div>
          <div className='w-[100%] lg:w-[50%]'>
            <img src='/assets/img/service/abouts4.png' className='w-[70%] mx-auto'></img>
          </div>
        </div>
        <h1 className='capitalize text-3xl font-bold text-center mt-10'>The team who built Wyse health</h1>
        <div className="w-[90%] mx-auto flex flex-col lg:flex-row justify-center gap-10 py-10">
          <div className="w-[100%] lg:w-1/3 ">
            <div className="service-item overflow-hidden bg-[#D9D9D92B] shadow-md rounded-xl position-relative">
              <div className="thumb overflow-hidden position-relative">
                <img
                  src='/assets/img/testimonial/sakthi.svg'
                  alt="img"
                  className="rounded-4 overflow-hidden w-100"
                />
              </div>
              <div className="mt-2">
                <h4 className="black font-medium font-Outfit text-lg"> Mr. Sakthi Visakan Rajaguru</h4>
                <p className='mb-0'>Director & Mentor</p>
              </div>
            </div>
          </div>
          <div className="w-[100%] lg:w-1/3 ">
            <div className="service-item overflow-hidden bg-[#D9D9D92B] shadow-md rounded-xl position-relative">
              <div className="thumb overflow-hidden position-relative">
                <img
                  src='/assets/img/testimonial/anna.svg'
                  alt="img"
                  className="rounded-4 overflow-hidden w-100"
                />
              </div>
              <div className="mt-2">
                <h4 className="black font-medium font-Outfit text-lg"> Mr. Annamalai Palaniappan</h4>
                <p className='mb-0'>Co-founder | Director & CEO </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}