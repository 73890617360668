import React, { useState } from 'react'
import Header from '../Layout/Header'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../Layout/Footer';


export default function Practition() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <Header />
      <div className='SECTION-1  w-[80%] mx-auto flex flex-col lg:flex-row justify-center py-5'>
        <div className='lg:w-[60%]'>
          <div>
            <h1 className='font-bold 2xl:text-5xl lg:text-4xl text-2xl  mx-auto text-left'>Partner with Wyse Health</h1>
            <div className='2xl:w-[70%] lg:w-[90%] flex justify-end'>
              <img src='/assets/img/element/title-badge1.png.svg'></img>
            </div>
          </div>
          <div>
            <p className='leading-loose text-[#333435] 2xl:text-2xl'><span className='2xl:text-3xl lg:text-lg font-bold'>Wyse Health</span> provides advanced genetic insights and precision wellness solutions, allowing doctors to offer personalized recommendations and medical interventions based on individual genetic and lifestyle data. With over 2.2+ million genetic tests accomplished, our platform equips healthcare providers to guide patients in making informed decisions about prevention, treatment, and overall well-being.</p>
          </div>
        </div>
        <div className='lg:w-[40%] flex justify-center'>
          <img className='2xl:w-6/12 lg:w-8/12' src='/assets/img/element/Figure → Customer_icon.svg.svg'></img>
        </div>

      </div>
      <div className='SECTION-2 w-[80%] mx-auto py-5'>
        <h1 className='font-bold 2xl:text-5xl lg:text-4xl text-2xl  mx-auto text-left'>Why Partner with Wyse Health?</h1>
        <div className='py-3 flex flex-col gap-3'>
          <p className='text-black 2xl:text-xl lg:text-sm px-2 py-3 bg-[#ececec] rounded-lg'><li className='px-2'><span className='font-bold 2xl:text-2xl lg:text-sm'>2.2+ Million Genetic Tests Completed:</span> Leverage a wealth of genetic data for informed patient recommendations and decision-making.</li></p>
          <p className='text-black 2xl:text-xl lg:text-sm px-2 py-3 bg-[#ececec] rounded-lg'><li className='px-2'><span className='font-bold 2xl:text-2xl lg:text-sm'>Personalized Health Insights:</span> Doctors can access detailed genetic data to provide tailored treatment plans.</li></p>
          <p className='text-black 2xl:text-xl lg:text-sm px-2 py-3 bg-[#ececec] rounded-lg'><li className='px-2'><span className='font-bold 2xl:text-2xl lg:text-sm'>Informed Medical Interventions:</span>  Enable precise, data-driven treatment strategies based on genetic and lifestyle information.</li></p>
          <p className='text-black 2xl:text-xl lg:text-sm px-2 py-3 bg-[#ececec] rounded-lg'><li className='px-2'><span className='font-bold 2xl:text-2xl lg:text-sm'>Professional Support:</span> Receive the necessary tools, resources, and updates to seamlessly integrate genetics into your practice.</li></p>

        </div>
      </div>
      <div className='bg-[#ececec]'>
        <div className='SECTION-3 w-[80%] mx-auto flex flex-col lg:flex-row justify-center py-5'>
          <div className='lg:w-[50%]'>
            <div>
              <h1 className=' lg:px-20 text-center lg:text-left  font-bold 2xl:text-5xl lg:text-4xl text-2xl mx-auto'>Let’s connect</h1>
              <div>
                <img src='/assets/img/element/image 267.svg' className='mx-auto'></img>
              </div>
            </div>
          </div>
          <div className='flex justify-center lg:w-[50%]'>
            <div className="bg-white  rounded-2xl p-6 sm:p-8 w-full max-w-md border-2 border-[#9e9e9e]">
              <form className="space-y-6">
                <div>
                  <label className="block text-gray-600 text-sm">Enter your Email</label>
                  <input
                    type="email"
                    className="w-full text-black border-b-2 border-gray-300 outline-none p-2 focus:border-gray-500"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                </div>
                <div>
                <label className="block text-gray-600 text-sm">Enter your Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-full text-black border-b-2 border-gray-300 outline-none p-2 pr-10 focus:border-gray-500"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-3 text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-blue-500 border border-gray-300 rounded focus:ring-2"
                    checked={remember}
                    onChange={() => setRemember(!remember)}
                  />
                  <label className="ml-2 text-gray-600 text-sm">Remember me</label>
                </div>
                <a className="common-btn box-style first-box w-full px-5 d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100">
                  LET’S CONNECT
                </a>
              </form>
            </div>

          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
