import React, { useState } from 'react'
import Header from '../Layout/Header'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../Layout/Footer';

export default function Business() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <Header />
      <div className='SECTION-1  w-[80%] mx-auto flex flex-col lg:flex-row items-center justify-center py-5'>
        <div className='lg:w-[60%]'>
          <div>
            <div>
              <img className='lg:w-2/12 w-6/12 -translate-x-8 lg:-translate-x-5' src="/wyse_logo_png.png" alt="logo-img" />
              <h1 className='font-bold mx-auto text-left text-[#84BE71] -translate-y-10'>For employees</h1>
            </div>
            <h1 className='font-bold 2xl:text-5xl lg:text-4xl text-2xl  mx-auto text-left -translate-y-10'>Personalized Health for Peak Employee Performance</h1>
          </div>
          <div>
            <p className='mt-1 leading-loose text-[#333435] 2xl:text-2xl -translate-y-10'>We are dedicated to transforming wellness with science-backed, precision health programs. Our offerings are designed to meet the unique needs of every individual, integrating cutting-edge genomics, lifestyle insights, and personalized solutions to optimize health, vitality, and longevity.</p>
          </div>
          <a className="common-btn box-style first-box px-5 d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100">
            Partner with us
          </a>
        </div>
        <div className='lg:w-[40%] flex justify-center'>
          <img className='2xl:w-10/12 lg:w-11/12' src='/assets/img/element/Group 1597883326.png'></img>
        </div>

      </div>
      <div className='SECTION-2 w-[80%] mx-auto flex flex-col lg:flex-row justify-center py-5'>
        <div className='lg:w-[50%]'>
          <div>
            <h1 className=' lg:px-20 text-center lg:text-left  font-bold 2xl:text-5xl lg:text-4xl text-2xl  mx-auto'>Let’s connect</h1>
            <div>
              <img src='/assets/img/element/image 267.svg' className='mx-auto'></img>
            </div>
          </div>
        </div>
        <div className='flex justify-center lg:w-[50%]'>
          <div className="bg-white  rounded-2xl p-6 sm:p-8 w-full max-w-md border-2 border-[#9e9e9e]">
            <form className="space-y-6">
              <div>
                <label className="block text-gray-600 text-sm">Enter your Email</label>
                <input
                  type="email"
                  className="w-full text-black border-b-2 border-gray-300 outline-none p-2 focus:border-gray-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="relative">
                <label className="block text-gray-600 text-sm">Enter your Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full text-black border-b-2 border-gray-300 outline-none p-2 pr-10 focus:border-gray-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-3 text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-blue-500 border border-gray-300 rounded focus:ring-2"
                  checked={remember}
                  onChange={() => setRemember(!remember)}
                />
                <label className="ml-2 text-gray-600 text-sm">Remember me</label>
              </div>
              <a className="common-btn box-style first-box w-full px-5 d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100">
                LET’S CONNECT
              </a>

            </form>
          </div>

        </div>
      </div>
      <Footer/>
    </div>
  )
}
