import React, { createContext, useState, useContext, useEffect } from 'react';

const currencySymbols = {
  usd: '$ ',      // US Dollar
  eur: '€ ',      // Euro
  gbp: '£ ',      // British Pound
  inr: '₹ ',      // Indian Rupee
  aud: 'A$ ',     // Australian Dollar
  cad: 'C$ ',     // Canadian Dollar
  jpy: '¥ ',      // Japanese Yen
  aed: ' د.إ',    // UAE Dirham
  chf: 'CHF ',    // Swiss Franc
  sgd: 'S$ ',     // Singapore Dollar
  myr: 'RM ',     // Malaysian Ringgit
  lkr: 'LKR ',     // Sri Lankan Rupee
  nzd: 'NZ$ ',    // New Zealand Dollar
};

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "inr");
  const [rates, setRates] = useState();  // To store live exchange rates
  const [currencyRate, setcurrencyRate] = useState();  // To store live exchange rates
  const [loading, setLoading] = useState(true);  // To track loading status

  useEffect(() => {
    // Function to fetch live exchange rates from the API
    const fetchLiveRates = async () => {
      try {
        // Get the current date in 'YYYY-MM-DD' format
        const currentDate = new Date().toISOString().split('T')[0];
    
        const response = await fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@${currentDate}/v1/currencies/inr.json`); // Use the dynamic date in the URL
        const data = await response.json();
        console.log("fetchLiveRates : ", data);
        if (data && data.inr) {
          setRates(data.inr);
          console.log("In Context",data.inr[currency])
          setcurrencyRate(data.inr[currency])
        }
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLiveRates();
  }, []); // Empty dependency array to run only once when component mounts

  const convertPrice = (priceInINR) => {
    if (loading) {
      return "Loading..."; // Display loading text while fetching live rates
    }

    const rate = rates[currency];
    if (rate) {
      const convertedPrice = (priceInINR * rate).toFixed(2);
      const currencySymbol = currencySymbols[currency] || ''; // Get the symbol for the selected currency
      return `${currencySymbol}${convertedPrice}`;
    }
    return priceInINR; // Return original price if no conversion rate is found
  };

  return (
    <CurrencyContext.Provider value={{ currency,currencyRate, setCurrency, convertPrice }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  return useContext(CurrencyContext);
};
