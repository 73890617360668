import React from 'react';
import CountUp from 'react-countup';


export default function Section3() {
    return (
        <section>
            <div className='cmn-bg'>
                <div className='container flex flex-col lg:flex-row gap-5 items-center '>
                    <div className='w-[100%] lg:w-[50%]'>
                        <h1 className='font-bold text-2xl md:text-4xl 2xl:text-5xl visible-slowly-right'>Live a life optimized for performance and overall
                            <span className="position-relative z-1 w-100 text-2xl md:text-4xl 2xl:text-5xl ml-2">
                                well-being.
                                <img
                                    src="assets/img/element/title-badge1.png"
                                    alt="img"
                                    className="title-badge1 d-md-block d-none w-100 translate-y-3"
                                />
                            </span>
                        </h1>
                        <p className='leading-8 mt-4'>Wyse Health has helped thousands of individuals achieve optimal health using the world’s most advanced, Gen AI technology to create a blueprint for your best health.</p>
                        <button className="relative group mt-4 bg-[#84BE71] text-black font-medium px-4 py-2 rounded-full overflow-hidden flex justify-center items-center">
                            <span className="relative z-10 duration-500 group-hover:text-white">
                                Build your plan
                            </span>
                            <span className="absolute inset-0 bg-black scale-0 transition-transform duration-500 group-hover:scale-100 rounded-full"></span>
                        </button>
                    </div>
                    <div className='w-[100%] lg:w-[50%] reveal-right'>
                        <video src='/assets/video/3rd Div Wyse Loop 2.mp4' className='w-[75%] mx-auto rounded-xl' autoPlay muted loop playsInline controls={false}>
                        </video>
                    </div>
                </div>
            </div>
            <div className='container'>
                <h1 className='text-2xl md:text-4xl 2xl:text-5xl font-bold text-center wow fadeInUp visible-slowly-right' data-wow-delay=".5s">Our Milestones in Genetic Testing</h1>
                <div className='mt-5 grid md:grid-cols-2 lg:grid-cols-4 gap-3 w-[90%] mx-auto'>
                    <div className='cmn-bg rounded-xl px-4 py-5 text-center'>
                        <div className='flex justify-center'>
                            <p className='font-Inter font-bold text-2xl md:text-3xl bg-gradient-to-b from-[#82EB5F] to-[#498536] bg-clip-text text-transparent'><CountUp start={0}  end={25} className='text-2xl' duration={5} /> + million</p>
                        </div>
                        <p className='mt-2 font-medium text-black'>Scientific publications Monitored</p>
                    </div>
                    <div className='cmn-bg rounded-xl px-4 py-5 text-center'>
                        <div className='flex justify-center'>
                            <p className='font-Inter font-bold text-2xl md:text-3xl bg-gradient-to-b from-[#82EB5F] to-[#498536] bg-clip-text text-transparent'><CountUp start={0}  end={16} className='text-2xl' duration={5.2} /> + million</p>
                        </div>
                        <p className='mt-2 font-medium text-black'>Polymorphisms & Mutations Analyzed</p>
                    </div>
                    <div className='cmn-bg rounded-xl px-4 py-5 text-center'>
                        <div className='flex justify-center'>
                            <p className='font-Inter font-bold text-2xl md:text-3xl bg-gradient-to-b from-[#82EB5F] to-[#498536] bg-clip-text text-transparent'><CountUp start={0}  end={40} className='text-2xl' duration={5.4} /> + million</p>
                        </div>
                        <p className='mt-2 font-medium text-black'>Genetic markers</p>
                    </div>
                    <div className='cmn-bg rounded-xl px-4 py-5 text-center'>
                        <div className='flex justify-center'>
                            <p className='font-Inter font-bold text-2xl md:text-3xl bg-gradient-to-b from-[#82EB5F] to-[#498536] bg-clip-text text-transparent'><CountUp  start={0}  end={98} className='text-2xl' duration={5.6} /> %</p>
                        </div>
                        <p className='mt-2 font-medium text-black'>Accuracy with Results</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
