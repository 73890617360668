import { legacy_createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import {thunk} from 'redux-thunk'; // Import redux-thunk
import storage from 'redux-persist/lib/storage';
import userReducer from './userReducer';

// Redux Persist configuration
const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['user', 'cart'],
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store with thunk middleware
const store = legacy_createStore(persistedReducer, applyMiddleware(thunk));

// Persistor to sync storage
const persistor = persistStore(store);

export { store, persistor };
