import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

export default function SuccessTransaction() {
  return (
    <div className='bg-[#F7F7F7] min-h-screen '>
      <Header />
      <div className='py-5 lg:py-20'>
        <div className='w-[90%] md:w-[80%] mx-auto border shadow-lg rounded-2xl p-3 md:p-5 text-center'>
          <p className='font-Outfit font-medium text-black text-xl md:text-2xl'>Thank you for your purchase! Your transaction has been <br/> successfully completed.
          </p>
          <img src='/assets/video/success.gif' className='mx-auto md:w-[40%]'></img>
          <p>For further details, please check your email for the confirmation and order summary.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
