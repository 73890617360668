import React from 'react'
import Header from '../Layout/Header'

export default function Shop() {
    return (
        <div>
            <Header />
            <div className='flex flex-col lg:flex-row w-[90%] items-center gap-10 lg:gap-0 mx-auto mt-10'>
                <div className='w-[100%] lg:w-[50%]'>
                    <div className="section-title px-10 py-14 2xl:px-14 2xl:py-20 bg-[#D9D9D938] rounded-lg">
                        <div className='w-[90%] mx-auto'>
                            <h2
                                className="text-3xl 2xl:text-4xl font-semibold wow fadeInUp black visible-slowly-right"
                                data-wow-delay=".3s"
                            >
                                Register your
                                <span className="position-relative z-1 mb-40 text-2xl 2xl:text-3xl ml-2 font-semibold">
                                    WYSE HEALTH
                                    <img
                                        src="assets/img/element/title-badge1.png"
                                        alt="img"
                                        className="title-badge1 d-md-block d-none w-100 "
                                    />
                                </span>
                                <br />
                                DNA Test Kit
                            </h2>
                            <p>Congratulations! You're one step closer to unlocking valuable insights into your health with the Wyse Health DNA Test Kit.</p>
                            <h1 className='font-Outfit font-bold'>Get Your Kit Today</h1>
                            <p>Explore your genetic data and take control of your wellness journey.</p>
                            <div className='flex justify-center mt-7'>
                                <a className="common-btn box-style first-box px-10 lg:px-20 d-inline-flex justify-content-center align-items-center gap-xxl-2 gap-2 fs18 fw-semibold black overflow-hidden p1-bg rounded100">
                                    SHOP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[100%] lg:w-[50%]'>
                    <img src='/assets/img/service/shop.svg' className='mx-auto'></img>
                </div>
            </div>
        </div>
    )
}
