import React from 'react'

export default function Section5() {
    return (
        <section>
            <div className='container flex flex-col lg:flex-row gap-5 items-center justify-between'>
                <div className='w-[100%] lg:w-[50%] reveal-left'>
                    <img
                        src="Manis_SUBRAMANIAN_a_genetic_engineer_researching_dna_in_same_dimensi_13ce132d-6088-4158-a4dc-02039cf202a7.png"
                        alt="img"
                        className="w-[75%] mx-auto rounded-4"
                    />
                </div>
                <div className='w-[100%] lg:w-[50%]'>
                    <p className='text-[#84BE71] font-bold text-lg'>NEW</p>
                    <h1 className='font-bold text-2xl md:text-4xl 2xl:text-5xl'>The world’s first precision health GPT</h1>
                    <p className='leading-8 mt-3'>With our Gen AI health coach, receive instant, personalized health advice based on your unique health data genetics, lab results, lifestyle, symptoms, conditions and goals.</p>
                    <p className='leading-8 mt-2'>From creating customized optimization plans to addressing specific health concerns, get 24/7 access to expert guidance that adapts to your needs.</p>
                    <button className="relative group mt-4 bg-[#84BE71] text-black font-medium px-4 py-2 rounded-full overflow-hidden flex justify-center items-center">
                        <span className="relative z-10 duration-500 group-hover:text-white">
                            Build your plan
                        </span>
                        <span className="absolute inset-0 bg-black scale-0 transition-transform duration-500 group-hover:scale-100 rounded-full"></span>
                    </button>
                </div>
            </div>
        </section>
    )
}
