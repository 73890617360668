import React from 'react'

export default function Section10() {

    const Blogs = [
        {
            blog_image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*UOWAgrD6KpBbqOdJEF_JYQ.jpeg",
            blog_content_heading: "Achieving Vibrant Skin with DNA-Powered Precision",
            blog_content: "Decode Personalized Beauty with GenAI",
        },
        {
            blog_image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*VT-NchnrijSTORzW6qnakg.jpeg",
            blog_content_heading: "The Role of DNA Insights in Revolutionizing Corporate Wellness",
            blog_content: "Wellness defined with DNA and GenAI"
        },
        {
            blog_image: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*J2XrPph5YiEUoY9fYTgVTw.jpeg",
            blog_content_heading: "DNA-Powered Precision Health for Weight Management",
            blog_content: "Gen-AI Meets Precision health for Weight Management",
        },
    ]
    return (
        <div>
            <div className="video-section cmn-bg fix">
                <div className="container">
                    <div className="video-uniquewrap position-relative d-center w-100">
                        <img
                            src="https://images.inc.com/uploaded_files/image/1920x1080/getty_858544742_373138.jpg"
                            alt="img"
                            className="w-100 rounded-4"
                        />
                        <a
                            href="https://wyseglobal.s3.ap-south-1.amazonaws.com/assets/video/Wyse+HRDV16.mp4"
                            target='_blank'
                            className="video-choose position-absolute d-center rounded-circle p1-bg "
                        >
                            <i className="fa-solid fa-play white" />
                        </a>
                    </div>
                </div>
            </div>

            {/*Stay Ahead */}
            <section>
                <div className='container'>
                    <h1 className="text-center font-bold text-2xl md:text-4xl 2xl:text-5xl black wow fadeInUp " data-wow-delay="0.6s">Stay Ahead with the Latest from
                        <span className="position-relative z-1 ml-2 text-2xl md:text-4xl 2xl:text-5xl">
                            Wyse Health
                            <img
                                src="assets/img/element/title-badge1.png"
                                alt="img"
                                className="title-badge1 d-md-block d-none w-100 translate-y-3"
                            />
                        </span>
                    </h1>
                    <div className='mt-5'>
                        <h1 className='text-[#0000005C] text-xl mt-3'>Latest Blogs</h1>
                        <div className='flex flex-col lg:flex-row gap-10 mt-2'>
                            <div className='w-[100%] lg:w-[60%] space-y-5'>
                                {Blogs.map((item, index) => (
                                    <div key={index} className='flex flex-col lg:flex-row gap-4 items-center'>
                                        <img className='w-[100%] lg:w-[35%] rounded-lg' src={item.blog_image}></img>
                                        <div className='w-[100%] lg:w-[65%]'>
                                            <p className='font-bold text-black text-2xl mb-1'>{item.blog_content_heading}</p>
                                            <p >{item.blog_content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='w-[100%] lg:w-[40%]'>
                                <video controls className="w-full rounded-lg">
                                    <source src="https://wyseglobal.s3.ap-south-1.amazonaws.com/assets/video/Wyse+Health+D5+gean+CXO+v01.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}