import React from 'react'

export default function Section6() {
    return (
        <div className='cmn-bg'>
            <div className='container flex flex-col lg:flex-row gap-5 items-center'>
                <div className='w-[100%] lg:w-[50%]'>
                    <p className='text-[#84BE71] font-bold text-lg'>NEW</p>
                    <h1 className='font-bold text-2xl md:text-4xl 2xl:text-5xl'>Pharmacogenomics (PGx) Report</h1>
                    <p className='leading-8 mt-3'>Understand how your genetic makeup influences your medication responses with our Pharmacogenomics (PGx) Report.</p>
                    <p className='leading-8 mt-2'>Gain clear, customized insights to guide you and your doctor in selecting the most effective treatments, minimizing side effects, and optimizing your healthcare journey.</p>
                    <button className="relative group mt-3 bg-[#84BE71] text-black font-medium px-4 py-2 rounded-full overflow-hidden flex justify-center items-center">
                        <span className="relative z-10 duration-500 group-hover:text-white">
                            Build your plan
                        </span>
                        <span className="absolute inset-0 bg-black scale-0 transition-transform duration-500 group-hover:scale-100 rounded-full"></span>
                    </button>
                </div>
                <div className='w-[100%] lg:w-[50%] reveal-right'>
                    <img src='/assets/img/element/section6.svg'></img>
                </div>
            </div>
        </div>
    )
}
